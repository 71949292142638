import PublicTopNav from "../components-reusable/Navigation/PublicTopNav/PublicTopNav";
import Footer from "../components-reusable/Navigation/Footer/Footer";
import Header from "../components-page/Home/Header/Header";
import CallToAction from "../components-page/Home/CallToAction/CallToAction";
import { NextSeo } from "next-seo";
import { ThemeContext } from "../contexts/ThemeContext/ThemeContext";
import { useContext, useEffect } from "react";
import PlatformsList from "../components-page/Home/PlatformsList/PlatformsList";
import * as contentful from "contentful";
import LargeFeatureRow from "../components-page/Home/LargeFeatureRow/LargeFeatureRow";
import Image1 from "../public/home_page_1.png";
import TierExplainer from "../components-page/Home/TierExplainer/TierExplainer";
const client = contentful.createClient({
  space: "bsnbd6wv79oo",
  environment: "master",
  accessToken: "ZJ8ZaSD5Uc11ITplKX0sTrxNHaHlLJGjnyAN5szS8mo",
});

export default function LandingPage({ platforms }) {
  const { setTheme } = useContext(ThemeContext);
  useEffect(() => {
    setTheme("light");
  }, []);

  return (
    <div className="bg-white">
      <NextSeo title="Scheduling Platforms | Reviews, Guides, Pricing and more!" />
      <PublicTopNav />
      <Header />
      <LargeFeatureRow
        offColor
        title="Choosing the Right Scheduling Platform Matters."
        description={
          <>
            Unlike other software, your scheduling platform is the center of
            your business. Your clients use it to access your business, and your
            employee's productivity is dependent on this decision. With over 100
            platforms to pick from, there's only a handful that are best for
            your business. Even more important, you need a platform that scales
            with your business and employees.
          </>
        }
        features={[
          "Find all scheduling platforms in one place",
          "Compare features, pricing, and reviews",
          "Get expert help when you need it",
        ]}
        image={Image1}
        imageLocation="left"
      />
      <TierExplainer />
      <PlatformsList platforms={platforms} />
      <CallToAction />
      <Footer />
    </div>
  );
}

export async function getStaticProps() {
  const data = await client.getEntries({
    content_type: "platform",
  });

  return {
    props: {
      platforms: data.items,
    },
  };
}
