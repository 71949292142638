import Image from "next/image";
import homepageImage from "../../../public/images/homepage_hero.jpg";

const Header = () => {
  return (
    <div className="max-w-7xl mx-auto lg:flex items-center">
      <div className="pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32 flex-1">
        <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
          <div className="sm:text-center lg:text-left">
            <h1 className="tracking-tight font-extrabold text-gray-900 ">
              <span className="text-3xl sm:text-5xl md:text-5xl block">
                Find the Perfect Scheduling Platform{" "}
              </span>{" "}
              <span className="block text-primary-500 text-2xl sm:text-3xl md:text-3xl">
                for your Business.
              </span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              All the information you need to choose a scheduling platform for
              your business. Reviews, pricing, features, support and more from
              scheduling experts at SchedulingPlatforms.com
            </p>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="/platforms"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 md:py-4 md:text-lg md:px-10"
                >
                  Get started
                </a>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <a
                  href="https://savvycal.com/brian-gorham/30-min-meet"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200 md:py-4 md:text-lg md:px-10"
                >
                  Talk with Expert
                </a>
              </div>
            </div>
            <p className="mt-3 text-sm text-gray-600">100% Free, Forever</p>
          </div>
        </main>
      </div>
      <div className="flex flex-1 justify-center">
        <div className="w-[600px]">
          <Image
            layout="responsive"
            priority
            src={homepageImage}
            alt="colorful building illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
