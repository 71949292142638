import { ChevronRightIcon } from "@heroicons/react/outline";
import SmartLink from "../../../components-reusable/Common/SmartLink/SmartLink";
const tiers = [
  {
    number: 1,
    title: "Meeting Scheduler",
    description:
      "Platforms designed to schedule meetings between two or more people.",
  },
  {
    number: 2,
    title: "General Booking",
    description:
      "Multi-industry platform for companies to schedule bookings or appointments.",
  },
  {
    number: 3,
    title: "Field Service",
    description:
      "Advanced software for booking, dispatching, and managing a field service business.",
  },
  {
    number: 4,
    title: "Industry Specific",
    description:
      "Platforms dedicated to a single industry with hyper specific feature sets.",
  },
];

const TierExplainer = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 lg:px-8">
      <div className="my-16 lg:my-28">
        <h2 className="text-4xl font-bold text-gray-900">
          Introducing Platform Tiers
        </h2>
        <p className="mt-4 text-lg text-gray-500 max-w-[700px]">
          All platforms are grouped into four tiers. This tells you the general
          feature set and purpose of the platform. Based on your business size,
          industry, and needs, we recommend starting your search here.
        </p>
        <div className="mt-8 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          {tiers.map((tier) => (
            <div
              className="flex-1 bg-primary-50 rounded-2xl p-6 relative flex flex-col justify-between hover:shadow-xl ease-in-out hover:scale-105 transition-all"
              key={tier.number}
            >
              <div className="text-9xl text-primary-200 bottom-0 right-6 absolute">
                {tier.number}
              </div>
              <div>
                <div className="text-xl font-semibold text-primary-800">
                  {tier.title}
                </div>
                <div className="text-md text-primary-700 mt-2 pr-[80px] mb-3">
                  {tier.description}
                </div>
              </div>
              <SmartLink
                href={`/platforms?tier=${tier.number}`}
                className="text-xs text-gray-800"
              >
                <span className="flex items-center">
                  View tier {tier.number}
                  <ChevronRightIcon className="w-3 h-3 ml-1" />
                </span>
              </SmartLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TierExplainer;
