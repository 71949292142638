import { CheckIcon } from "@heroicons/react/solid";
import Image from "next/image";
import Lottie from "react-lottie";

const LargeFeatureRow = ({
  title,
  description,
  features,
  imageLocation,
  image,
  lottie,
  offColor,
}) => {
  return (
    <div className={offColor ? "bg-gray-50" : "bg-white"}>
      <div className="max-w-7xl mx-auto px-4 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center py-16 lg:py-28">
          <div
            className={`flex-1 mt-8 md:mt-0 ${
              imageLocation == "left"
                ? "order-2 md:order-1"
                : "order-2 md:order-2"
            } w-full`}
          >
            {lottie && (
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: lottie,
                }}
                height={400}
                width={"100%"}
              />
            )}
            {image && (
              <Image
                layout="responsive"
                src={image}
                alt="Tap For Service Dashboard Page"
                className="rounded-xl"
              />
            )}
          </div>
          <div
            className={`flex-1 ${
              imageLocation == "left"
                ? "order-1 md:order-2 md:pl-24"
                : "order-1 md:order-1 md:pr-24"
            }`}
          >
            <div className="my-4">
              <div className="mt-6">
                <h2 className="text-4xl font-bold text-gray-900">{title}</h2>
                <p className="mt-4 text-lg text-gray-500">{description}</p>
                <div className="mt-6">
                  {features.map((feature, index) => (
                    <div
                      className="flex items center text-gray-700 text-md"
                      key={index}
                    >
                      <CheckIcon className="w-6 h-6 mr-3 text-green-700" />{" "}
                      {feature}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LargeFeatureRow;
