import { ArrowRightIcon } from "@heroicons/react/outline";
import PrimaryButton from "../../../components-reusable/Buttons/PrimaryButton/PrimaryButton";
import SinglePlatform from "./SinglePlatform/SinglePlatform";

const PlatformsList = ({ platforms }) => {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-16 lg:py-28  px-4 lg:px-8">
        <h2 className="text-primary-500 text-4xl sm:text-3xl md:text-3xl font-extrabold mb-4">
          Top Scheduling Platforms 2022
        </h2>
        <div className="divide-y">
          {platforms.map((platform) => (
            <SinglePlatform
              key={platform.fields.name}
              name={platform.fields.name}
              logo={platform.fields.logo.fields.file.url}
              oneLiner={platform.fields.oneLiner}
              about={platform.fields.about}
              labels={platform.fields.top3Labels}
              tier={platform.fields.tier}
              slug={platform.fields.slug}
            />
          ))}
        </div>
        <div className="flex justify-center my-6">
          <PrimaryButton size="xl" href="/platforms">
            View All Platforms{" "}
            <ArrowRightIcon className="ml-2 w-5 h-5 animate-pulse" />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default PlatformsList;
