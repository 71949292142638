import { CheckCircleIcon, CollectionIcon } from "@heroicons/react/outline";
import SecondaryButton from "../../../../components-reusable/Buttons/SecondaryButton/SecondaryButton";
import Badge from "../../../../components-reusable/Common/Badge/Badge";

const SinglePlatform = ({
  name,
  logo,
  oneLiner,
  about,
  labels,
  slug,
  tier,
}) => {
  return (
    <div className="sm:flex sm:space-x-10 space-y-4 sm:space-y-0 items-center my-4 p-5 rounded-lg hover:shadow-sm bg-white">
      <div className="w-full h-30 sm:w-40 sm:h-40 sm:p-6 bg-white sm:bg-gray-50 rounded-lg flex justify-between sm:justify-center sm:items-center relative">
        <img src={logo} className="w-full h-auto max-w-[200px]" />
        <div className="text-primary-500 sm:absolute sm:-bottom-3 opacity-80 hover:opacity-100">
          <Badge color="primary">
            <CollectionIcon className="w-5 h-5 mr-2" />
            {tier.substring(0, 6)}
          </Badge>
        </div>
      </div>
      <div className="flex-1">
        <div className="text-xl font-bold">{name}</div>
        <div className="text-sm">{oneLiner}</div>
        <div className="text-xs mt-2 line-clamp-2">{about}</div>
        <div className="mt-2 sm:flex sm:space-x-2 flex-wrap">
          {labels?.map((label) => (
            <Badge color="gray" key={label}>
              <CheckCircleIcon className="w-5 h-5 mr-2" />
              {label}
            </Badge>
          ))}
        </div>
      </div>
      <div className="">
        <SecondaryButton href={`/platforms/${slug}`} size="md">
          Learn More
        </SecondaryButton>
      </div>
    </div>
  );
};

export default SinglePlatform;
