import React from "react";

// Tailwind classes needed:
// bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-100
// bg-gray-100 dark:bg-gray-900 text-gray-700 dark:text-gray-100
// bg-yellow-100 dark:bg-yellow-900 text-yellow-700 dark:text-yellow-100
// bg-green-100 dark:bg-green-900 text-green-700 dark:text-green-100

const Badge = ({
  color = "",
  children,
  customBackgroundColor,
  customFontColor,
  className = "",
}) => {
  return (
    <span
      style={{
        backgroundColor: customBackgroundColor,
        color: customFontColor,
      }}
      className={`whitespace-nowrap inline-flex rounded-full uppercase px-3 py-1 text-xs font-semibold leading-5 ${
        !color.startsWith("#") &&
        `bg-${color}-100 dark:bg-${color}-900 text-${color}-700 dark:text-${color}-100 ${className}`
      }`}
    >
      {children}
    </span>
  );
};

export default Badge;
